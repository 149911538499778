import { Formatter } from 'shared'
import ApiModel from 'base/api-model'
import EventOccurrence from './event-occurrence'
import EventApi        from './event-api'
import SmartButtonApi  from './smart-button-api'
import GuestPassApi    from './guest-pass-api'

export default class Event extends ApiModel {
  constructor(attributes) {
    super(attributes)
    this.relationship = this.relationship || {}
  }

  get image_url() { return this.resources && this.resources.length ? this.resources[0].file : null }
  get image_preview_url() { return this.resources && this.resources.length ? this.resources[0].preview : null }
  get place_name() { return this.place && this.place.name }
  get address() { return this.place && this.place.address }

  get openMoment()  { return moment(`${this.date} ${this.openTime || ''}`.trim()) }
  get closeMoment() { return moment(`${this.end_date || this.date} ${this.closeTime || ''}`.trim()) }
  get open_datetime() { return $.trim(`${this.date} ${this.openTime || ''}`) }

  get description() { return this.sub_text_html || this.sub_text }

  get address_map_url() {
    return this.address ?
      `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(this.address)}` :
      null
  }

  get showMap() {
    return this.place && 
      this.place.ui_features && 
      this.place.ui_features.includes('map');
  }

  get defaultTicketType() {
    return this.ticket_settings ?
      this.ticket_settings.find(ts => ts.is_default) || this.ticket_settings[0] :
      null
  }

  get requiresPayment() {
    return this.ticket_settings && this.ticket_settings.some(set => set.amount && set.amount > 0)
  }

  get uid() { return `${TypeMapping[this.type] || this.type}:${this.occurrence_id || this.id}` }
  get parkUid() { return this.isParkReservation && this.place && `park:${this.place.id}` }
  get targetUid() { return this.isParkReservation ? this.parkUid : this.uid  }

  get openTime() {
    if (this.open_time_local)
      return this.open_time_local
    if (this.isMultiday && this.occurrences)
      return this.occurrences[0].time
  }

  get closeTime() {
    if (this.close_time_local)
      return this.close_time_local
    if (this.isMultiday && this.occurrences)
      return this.occurrences[this.occurrences.length - 1].close_time
  }

  get isLiked() { return this.relationship.is_saved }
  get isPublished() { return this.relationship.is_public }
  get isReserved() { return this.relationship.is_reserved }
  get isWaitlisted() { return this.relationship.is_waitlisted }
  get isReservation() { return this.is_reservation }
  get isRecurring() { return this.is_recurring }
  get isLimited() { return this.is_limited }
  get noAvailability() { return this.no_availability }
  get isMultiday() { return this.category == 'multiday' }
  get isParkReservation() { return this.type == 'approved-reservation-event' }

  get isInterested() { return this.relationship.subscription_status == 'interested' }
  get isEnrolled() { return (this.relationship.subscription_status_web == 'active') }
  get isEnrollmentPending() { return this.relationship.subscription_status == 'pending' }
  get isSystemRecommendation() { return this.relationship.recommended_by == 'trigger' }
  get isFriendRecommendation() { return this.relationship.recommended_by != 'trigger' && this.relationship.recommended_by != null }

  get isEventlike() { return this.type == 'event:details' || this.type == 'event:occurrence' }
  get isRegular() { return this.category == 'regular' }
  get isEvent() { return this.type == 'event:details' }
  get isFamily() { return this.category == 'family' }

  get badge() {
    if (this.isParkReservation && this.reservedService) return `${this.reservedService.name} Reservation`
    if (this.isReserved) return "Reserved"
    if (this.isWaitlisted) return "Waitlisted"
    if (this.isEnrolled) return "Enrolled"
    if (this.isParkReservation) return "Reserved"
    if (this.isInterested) return "Interested"
    if (this.isLiked) return "Saved"
    if (this.isSystemRecommendation) return "Suggested For You"
    if (this.isFriendRecommendation) return `Recommended by ${this.relationship.recommended_by}`
    return null
  }

  get multipleBadge() {
    if (this.isLimited) return "Limited Availability"
    if (this.noAvailability) return "No Availability"
    return null
  }  

  get coords() { return (this.place && this.place.latitude) ? { lat: this.place.latitude, lng: this.place.longitude } : null }
  get cachedOccurrences() { return Object.values(App.state.entities).filter(e => e.type == "event:occurrence" && e.id == this.id)}

  getOccurrence(occurrenceId) {
    return this.occurrences && this.occurrences.find(occ => occ.id == occurrenceId)
  }

  get hasOccurrences() { return this.occurrences && this.occurrences.length != 0 }
  get occurrenceObjects() { return this.occurrences && this.occurrences.map(occ => new EventOccurrence(this, occ)) }
  get reservableOccurrences() { return this.occurrences && this.occurrenceObjects.filter(occ => occ.canBeReserved) }
  get hasReservableOccurrences() { return this.recurring && this.reservableOccurrences && this.reservableOccurrences.length > 0 }
  get waitlistedItemsId() { return this.waitlist_items_id }

  get reservedService() { return this.reservation && this.reservation.service }


  async like() {
    this.relationship.is_saved = !this.relationship.is_saved
    App.state.updated({ scope: this.uid })
    let event = await EventApi.like(this) // updated self or base event for occurrences
    let cachedOccurrences = event.cachedOccurrences
    for (let occurrence of cachedOccurrences) {
      if (occurrence != this) {
        occurrence.relationship.is_saved = event.relationship.is_saved
        occurrence.like_count = event.like_count
      }
    }
    App.state.updated({ scope: cachedOccurrences.map(occ => occ.uid) })
    EventApi.refresh('mineGrouped')
  }

  async publish() {
    this.relationship.is_public = !this.relationship.is_public
    App.state.updated({ scope: this.uid })
    await EventApi.publish(this)
    EventApi.refresh('mineGrouped')
  }

  async reload()             { await EventApi.show(this.id) }
  async subscribe()          { await EventApi.subscribe(this) }
  async cancelSubscription() { await EventApi.cancelSubscription(this) }
  async cancelReservation()  { await EventApi.cancelReservation(this) }

  async reserve(identifier, tickets = {}, guest_ids= []) {
    let result = await EventApi.reserve({ id: identifier }, { spots:  tickets, guest_ids: guest_ids })
    EventApi.refresh('mineGrouped')
    await this.reload()
    return result
  }

  async cancelOccurrence(occurrence) {
    await EventApi.cancelReservation(occurrence)
    EventApi.refresh('mineGrouped')
    await this.reload()
  }

  async modifyReservation(identifier, tickets = {}, guest_ids= []) {
    let result = await EventApi.modifyReservation({ id: identifier }, { spots:  tickets, guest_ids: guest_ids })
    EventApi.refresh('mineGrouped')
    await this.reload()
    return result
  }

  async interestInOccurrence(occurrence) {
    await EventApi.subscribe(occurrence)
    EventApi.reset('mineGrouped')
    await this.reload()
  }

  async cancelInterestInOccurrence(occurrence) {
    await EventApi.cancelSubscription(occurrence)
    EventApi.reset('mineGrouped')
    await this.reload()
  }

  async track() {
    EventApi.track(this)
  }

  async saveToGuestPass() {
    await GuestPassApi.postEvent(this)
  }

  requestSmartButtons(success) {
    if (this.isParkReservation)
      return SmartButtonApi.forPark(this.place, null, success)
    if (this.isEventlike)
      return SmartButtonApi.forEvent(this, success)
  }


  static parseEventListId(string) {
    let [flags, period, smart_button] = string.split('__')
    return { flags, period, smart_button }
  }
}

const TypeMapping = {
  'event:details': 'event'
}
