import { Api } from 'base'
import Event from './event'
import Park from './park'

class EventApi extends Api {
  collectionName = 'events'
  recentlyTrackedEvents = {}

  async mineGrouped({ guest_experience_id } = {}) {
    let json = await Backend.get('/events/mine', { guest_experience_id })
    let models = json.events.map(r =>
      r.type == 'place:details' ?
        new Park(r) :
        new Event(r)
    )
    let groups = _.groupBy(models, 'date')
    return { groups, models }
  }

  async all(params)         { return this.index('all', params) }
  async list(params)        { return this.index('list', params) }
  async fitness(params)     { return this.index('fitness', params) }
  async programming(params) { return this.index('programming', params) }
  async upcoming(params)    { return this.index('upcoming', params) }
  async filtered(params)    { return this.index('filtered', params)}

  async index(path, params) {
    return (await Backend.get(`/events/${path}`, params)).events.map(r => new Event(r))
  }

  async track(event) {
    if (User.guest) return
    if (this.recentlyTrackedEvents[event.id]) return

    return this.performAction(event, 'track').then(
      this.recentlyTrackedEvents[event.id] = true
    )
  }

  async like(event)               { return this.performAction(event, 'toggle_saving') }
  async publish(event)            { return this.performAction(event, 'toggle_public') }
  async subscribe(event)          { return this.performAction(event, 'subscription') }
  async cancelSubscription(event) { return this.performAction(event, 'subscription', { method: 'DELETE' }) }
  async reserve(event, params)    { return this.performAction(event, 'reservation', { params }) }
  async cancelReservation(event)  { return this.performAction(event, 'reservation', { method: 'DELETE' }) }
  async modifyReservation(event, params)  { return this.performAction(event, 'reservation', { method: 'PATCH', params }) }

  async performAction(event, action, { method = 'post', params } = { }) {
    return this.convertRemoteError(async () => {
      return this.receiveModel(await Backend.request(method, `/events/${event.id}/${action}`, params))
    })
  }

  async show(id) {
    return this.receiveModel(await Backend.get(`/events/${id}`))
  }

  receiveModel(json) {
    if (json) {
      let model = new Event(json.event)
      this.state.updateModel(model)
      return model
    }
  }
}

export default new EventApi
